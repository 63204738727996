<template>
  <div>
    <v-card-title class="display-1 mb-2">
      {{t('$vuetify.serviceCenter.requestCharger')}}
      <v-spacer/>
      <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <v-icon
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              large
              >
                  mdi-progress-question
              </v-icon>
          </template>
          <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.<br>
              Magnam, neque totam quasi ipsum necessitatibus amet <br>
              excepturi ex corporis, aut impedit in ratione animi<br>
              fugit dolorem voluptatum vel recusandae eaque molestias!
          </span>
      </v-tooltip>
    </v-card-title>
            <v-card-subtitle v-if="previousStepName === 'ChargerType'">{{t('$vuetify.serviceCenter.requiredSub')}}</v-card-subtitle>
            <v-card-text>
            <v-radio-group v-model="radioGroupGuestCharging">
            <v-row>
                <v-col cols="12" lg="6">
                    <v-card flat outlined min-height="250px">
                        <v-card-text>
                            <v-radio :label="t('$vuetify.serviceCenter.subHome')"></v-radio>
                        </v-card-text>
                        <v-card-text align="center">
                            <v-img
                                width="35%"
                                lazy-src="/requests/chargepointpaid.png"
                                src="/requests/chargepointpaid.png"
                                class="mb-2"
                            ></v-img> <br>
                            <!-- + 160 € -->
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6">
                    <v-card flat outlined min-height="250px">
                        <v-card-text>
                            <v-radio
                                :label="t('$vuetify.serviceCenter.noSub')"
                            ></v-radio>
                        </v-card-text>
                        <v-card-text align="center">
                                <br>
                                <v-img
                                width="35%"
                                lazy-src="/other/chargepointsfree.png"
                                src="/other/chargepointsfree.png"
                                class="mb-2"
                            ></v-img>
                            <br>
                        </v-card-text>
                        <v-fade-transition>
        <v-overlay
          v-if="previousStepName === 'ChargerType'"
          absolute
          color="white"
          opacity="0.7"
        >
        </v-overlay>
      </v-fade-transition>
                    </v-card>
                </v-col>
            </v-row>
            </v-radio-group>
        </v-card-text>
      <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <!-- {{t('$vuetify.serviceCenter.totPrice')}} : {{totPrice}} € -->
      <v-spacer />
      <v-btn
          color="primary"
          text
          @click="previousStep()"
      >
          <v-icon>mdi-chevron-left</v-icon>
          {{t('$vuetify.generic.back')}}
      </v-btn>
      <v-btn
          color="success"
          text
          @click="nextStep()"
          :disabled="radioGroupGuestCharging === null"
      >
        {{t('$vuetify.generic.next')}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="cancel"
      >
        {{t('$vuetify.generic.cancel')}}
      </v-btn>
    </v-card-actions>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      radioGroupGuestCharging: 0,
      isIncludeCable: false,
      isDiffAdrs: false,
      langs: ['en', 'de'],

      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,

      // form
      isFormValid: true,
      email: '',
      password: '',

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      // show password field
      showPassword: false,

      isVerified: false,
      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      }
    }
  },
  computed: {
    ...mapGetters({ price: 'requestsState/price', previousStepName: 'requestsState/previousStepName', totPrice: 'requestsState/totalPrice' })
  },
  mounted () {
    this.setPrice(this.radioGroupGuestCharging)
  },
  methods: {
    nextStep () {
      this.setPrice(this.radioGroupGuestCharging)
      console.log('NEXT ')
      console.log(this.previousStepName)
      if (this.previousStepName === 'ChargerType') {
        this.$store.commit('requestsState/setSubscriptionType', '13845')
        this.$store.commit('requestsState/setStep', 'InvoiceAddress')
      } else {
        if (this.radioGroupGuestCharging) {
          this.$store.commit('requestsState/setSubscriptionType', '13846')
        } else {
          this.$store.commit('requestsState/setSubscriptionType', '13845')
        }
        this.$store.commit('requestsState/setStep', 'DynamicType')
      }
    },
    previousStep () {
      this.radioGroupGuestCharging = null
      this.$store.commit('requestsState/removePrice', 'subscriptiontype')
      this.$store.commit('requestsState/setSubscriptionType', '')
      this.$store.commit('requestsState/setPreviousStepName')
    },
    cancel () {
      this.$emit('cancel')
    },
    setPrice (val) {
      if (val !== null) {
        const elm = { price: 160, type: 'subscriptiontype' }
        if (this.previousStepName === 'ChargerType') {
          this.$store.commit('requestsState/setPrice', elm)
        } else {
          if (val) {
            elm.price = 0
            this.$store.commit('requestsState/setPrice', elm)
          } else {
            elm.price = 160
            this.$store.commit('requestsState/setPrice', elm)
          }
        }
      }
    }
  },
  watch: {
    radioGroupGuestCharging: function (val) {
      this.setPrice(val)
    }
  }
}
</script>
